/**
 * --------------------------------------------------------------
 * helper util
 * ex. date
 * --------------------------------------------------------------
 */
export const helper = {
  data() {
    return {
      mixGetTime: {
        chat: () => {
          // get current time for chat
          const tanggal = new Date();
          const month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ];

          return `${tanggal.getDate()} ${
            month[tanggal.getMonth()]
          } ${tanggal.getHours()}:${tanggal.getMinutes()}:${tanggal.getSeconds()}`;
        }
      }
    };
  }
};

/**
 * --------------------------------------------------------------
 * for icon gravatar
 * --------------------------------------------------------------
 */
import gravatar from "gravatar"; // for avatar
export const avatar = {
  methods: {
    mixGravatar: function(name = "addin@rw.com", config) {
      if (typeof config === "object") {
        return gravatar.url(name, config);
      }
      return gravatar.url(name, { d: "identicon" });
    }
  }
};

/**
 * --------------------------------------------------------------
 * generate message
 * 1. client/staf
 * 2. bot/ bukan
 * 3. etc
 * fromMe : dari sudut pandang staff jika false maka dari client
 * --------------------------------------------------------------
 */

export const message = {
  data() {
    return {
      mixGenerateMessage: {
        /**
         * --------------------------------------------------------------
         * generate message Bot
         * @param tipe ex. chooseDepartement, alert
         * --------------------------------------------------------------
         */
        bot: function(tipe, message = "") {
          return {
            name: "Bot",
            time: helper.data().mixGetTime.chat(),
            image: avatar.methods.mixGravatar("rwbot@rw.id"),
            bot: true,
            tipe: tipe,
            fromMe: true, // biar disamping kiri
            message: message
          };
        },
        human: function(name, message = "", fromMe = false) {
          return {
            name: name,
            time: helper.data().mixGetTime.chat(),
            image: avatar.methods.mixGravatar(name),
            bot: false,
            tipe: "message",
            fromMe: fromMe,
            message: message
          };
        }
      }
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * like as nl2br php
     * --------------------------------------------------------------
     */
    mixBr: function(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    }
  }
};
